import type { Response } from "@tengiva/services-api-layer/types/response";
import type { Pagination } from "@tengiva/services-api-layer/types/pagination";
import type {
	Category,
	Textile,
	PlatformCatalogProduct,
	CollectionShowroom,
	CollectionsItemsSummary,
} from "@/types/products";
import type { Order } from "@/types/filters";

export const usePM = () => {
	const {
		viewProduct,
		listCategoriesByPlatformTextilesApplication,
		listCollectionsItemsSummaryShowroom,
		viewCollectionShowroom,
		viewTextile,
		viewProductsShowroom,
	} = usePMApi();

	const { $sentry } = useNuxtApp();

	const emptyPagination: Pagination = {
		page: 0,
		limit: 0,
		total: 0,
		order: "",
		pages_count: 0,
		next: null,
		prev: null,
	};

	const listProductsForPlatform = async (
		platformId: string,
		page: number = 1,
		limit: number = 21,
		order: Order = {}
	): Promise<{ platformCatalogProducts: PlatformCatalogProduct[]; pagination: Pagination }> => {
		let platformCatalogProducts: PlatformCatalogProduct[] = [];
		let pagination: Pagination = emptyPagination;

		try {
			const { response, error } = (await viewProductsShowroom(platformId, {
				limit,
				page,
				order,
			})) as Response<{
				platform_catalog_products: PlatformCatalogProduct[];
				pagination: Pagination;
				total_in_stock_inventory: number;
			}>;

			if (error) throw error;

			if (response?.data) {
				platformCatalogProducts = response.data.platform_catalog_products || [];
				pagination = response.data.pagination;
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch platform catalog (${err.data?.error.message})`);

			return { platformCatalogProducts: [], pagination: emptyPagination };
		}
		return { platformCatalogProducts, pagination };
	};

	const viewProductById = async (product_id: string) => {
		const res = await viewProduct(product_id);
		if (res.error) {
			console.error(res.error);
			return undefined;
		}
		const product = res.response?.data;
		return product;
	};

	const listCategoriesForShowroom = async (org_id: string) => {
		const res = await listCategoriesByPlatformTextilesApplication({
			organization_id: org_id,
			platform_model: "Showroom",
		});
		if (res.error) {
			console.error(res.error);
			return [];
		}
		const categories = res.response?.data as Category[];
		return categories;
	};

	const listCollections = async (org_id: string) => {
		try {
			const { response, error } = (await listCollectionsItemsSummaryShowroom(
				org_id
			)) as Response<CollectionsItemsSummary>;

			if (error) throw error;

			if (response?.data?.results) {
				const collections = response?.data?.results;

				if (Array.isArray(collections)) {
					return collections.filter(c => c.visibility === true && c.collections_textile_items?.length);
				}
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch collections summary (${err.data?.error.message})`);
		}

		return [];
	};

	const viewCollection = async (identifier: string) => {
		// takes either id or slug as an identifier
		try {
			const { response, error } = (await viewCollectionShowroom(identifier, {})) as Response<CollectionShowroom>;

			if (error) throw error;

			if (response?.data) {
				return response.data;
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch showroom collection (${err.data?.error.message})`);
		}
	};

	const viewTextileById = async (textile_id: string) => {
		try {
			const { response, error } = await viewTextile(textile_id);

			if (error) throw error;

			return response?.data as Textile;
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch textile (${err.data?.error.message})`);

			return undefined;
		}
	};

	return {
		viewProductById,
		listCategoriesForShowroom,
		listCollections,
		viewCollection,
		viewTextileById,
		listProductsForPlatform,
	};
};
